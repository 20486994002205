import { FC } from 'react'

interface ContentHeaderDescriptionProps {
  text: string
}

export const ContentHeaderDescription: FC<ContentHeaderDescriptionProps> = ({ text }) => {
	return (
		<p className="mb-5">
			{text}
		</p>
	)
}