import { FC, ReactNode, createContext, useEffect, useState } from 'react'
import { Account } from 'types/Account'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { gql, useQuery } from '@apollo/client'

const GET_CLIENTS=gql`
  query {
    self {
      id
      name
      type
    }
  }
`


export interface AccountContextProps {
  isLogged: boolean
  loginPreform: (arg: string) =>  void;
  logoutPreform: () =>  void;
  isAccountValidating: boolean
  accessToken: string | null
  account?: Account
}

export const AccountContext = createContext<AccountContextProps>({
	isLogged: false,
	loginPreform: () => undefined,
	logoutPreform: () => undefined,
	isAccountValidating: true,
	accessToken: '',
	account: undefined,
})

export const AccountProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const [isLogged, setIsLogged] = useState<boolean>(false)
	const [isAccountValidating, setIsValidating] = useState<boolean>(true)
	const [accessToken, setAccessToken] = useState<string | null>(null)
	const { loading: apiLoading, data: accountData, refetch } = useQuery<{ self: Account }>(GET_CLIENTS)
	const { getValue } = useLocalStorage('access_token')
	const { setValue: setUserType } = useLocalStorage('account_type')

	const loginPreform = (token: string) => {
		setIsLogged(true)
		setAccessToken(token)
		refetch()
	}

	const logoutPreform = () => {
		setIsLogged(false)
	}

	const getAccessTokenValue = () => {
		getValue().then(data => {
			setIsLogged(!!data)
			setAccessToken(data)
		}).catch(error => {
			console.error({ error })
			setIsLogged(false)
		}).finally(() => {
			setIsValidating(false)
		})
	}

	useEffect(() => {
		getAccessTokenValue()
	}, [getValue()])

	return (
		<AccountContext.Provider value={{
			isLogged,
			loginPreform,
			logoutPreform,
			isAccountValidating: isAccountValidating || apiLoading,
			accessToken,
			account: accountData?.self,
		}}>
			{children}
		</AccountContext.Provider>
	)
}
