import { useContext } from 'react'
import { PageContext } from 'providers/Page'

export const usePage = () => {
	const { pageTitle, pageIcon } = useContext(PageContext)

	return {
		pageTitle,
		pageIcon,
	}
}