import { FC } from 'react'
import Link from '@mui/material/Link'
import { Link as LinkRouter } from 'react-router-dom'
import { routes } from 'globals/routes'

interface ClientNameProps {
  id: string
  name: string
}
export const ClientName: FC<ClientNameProps> = ({ id, name }) => {
	return (
		<Link
			component={LinkRouter}
			to={`${routes.clients}${routes.SHOW}/${id}/${routes.clientsShowTabs.general}`}>
			{name}
		</Link>
	)
}
