import { FC } from 'react'
import { TableRow } from '../TableRow'
import { TableCell } from '../TableCell'
import TableBody from '@mui/material/TableBody'
import { ActivityIndicator } from 'components/ActivityIndicator'

export const TableLoading = () => {
	return (
		<TableBody>
			<TableRow>
				<TableCell colSpan={30} sx={{
					background: 'none !important',
					textAlign: 'center'
				}}>
					<ActivityIndicator sx={{}} />
				</TableCell>
			</TableRow>
		</TableBody>
	)
}
