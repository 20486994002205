import Paper, { PaperProps } from '@mui/material/Paper'
import { purple, brown, green, orange } from '@mui/material/colors'
import { styled } from '@mui/material/styles'

export const StyledPaper = styled(Paper)<PaperProps>(({ theme }) => {
	return ({
		borderRadius: theme.spacing(1),
		marginBottom: theme.spacing(1),    
		padding: theme.spacing(1, 0, 2, 0),
	})
})
