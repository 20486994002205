import { useQuery, useMutation } from '@apollo/client'
import { Client } from 'types/Client'
import { useParams } from 'react-router-dom'
import { GET_CLIENT, ADD_CLIENT } from './useClient.gql'

export const useClient = () => {
	const { id } = useParams<{ id: string }>()

	const getClient = (clientId?: string) => useQuery<{ client: Client }>(GET_CLIENT, {variables: { id: clientId || id } })
  
	const [createClientBalance] = useMutation(ADD_CLIENT)

	return { getClient, createClientBalance }
}
