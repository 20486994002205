import { gql } from '@apollo/client'

export const GET_CLIENT=gql`
  query client($id: String!) {
    client(id: $id) {
      id
      name
      purchases {
        id
        paid
        notes
        createdAt
        updatedAt
        createdBy
        purchaseItems {
          id
          amount
          price
          product {
            id
            name
            unit
          }
        }
      }
      balances {
        id
        paid
        createdAt
        createdBy
        notes
      }
      products {
        id
        name
        amount
        unit
        paid
        available
        unitPrice
        createdAt
        updatedAt
        extraCosts {
          id
          paid
          notes
          order
        }
      }
      stocktakings {
        id
        notes
        stocktakedAt
      }
    }
  }
`

export const ADD_CLIENT = gql`
  mutation(
    $clientId: String!
    $paid: Float!
    $notes: String
  )
  {
    createClientBalance(
        clientId: $clientId,
        paid: $paid,
        notes: $notes
    ) {
        id
        paid
        notes
        client {
          id
          name
        }
    }
  }
`
