import { FC, ReactNode } from 'react'
import { useFromItemtyles } from './FormItem.styles'

export const FormItem: FC<{ children: ReactNode }> = ({ children }) => {
	// const classes = useFromItemtyles()

	return (
		<>
			{children}
		</>
	)
}
