import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { Client, ExtendedClient } from 'types/Client'
import { createPurchasesReview, createBalancesReview, createProductsReview } from 'globals/utils'
import { calculatePurchaseDebt, calculateBalance, calculateProductDebt, calculateProductPaid } from 'globals/math'
import { GET_CLIENTS } from './useCompiledClients.gql'

interface ClientsApiResponse {
  clients: Client[]
}

export const useCompiledClients = () => {
	const queryResult = useQuery<ClientsApiResponse>(GET_CLIENTS, { variables: { filterKeyword: '' }})

	const { data } = queryResult


	const dataCompiled = useMemo(() => {
		const stocktaked: ExtendedClient[] = []
		const notStocktaked: ExtendedClient[] = []


		data?.clients?.forEach((client) => {
			const purchasesReview = createPurchasesReview(client.purchases)
			const balancesReview = createBalancesReview(client.balances)
			const productsReview = createProductsReview(client.products)


			const purchaseDebt = calculatePurchaseDebt(purchasesReview)
			const balanceDebt = calculateBalance(balancesReview)
			const productsDebt = calculateProductDebt(productsReview)
			const productPaid = calculateProductPaid(client.products)

			const istUserStoktaked = client.products?.length === 0
        && client.purchases?.length === 0
        && client.balances
        && client.balances?.length <= 1

			const newName = client.name.replace(new RegExp(/(?:\()/gi, 'g'), '<br />(')
			const clientExtended = {
				...client,
				name: newName.replace(new RegExp(/(?:\/\/)/gi, 'g'), '<br />//'),
				purchaseDebt,
				balanceDebt,
				productsDebt,
				productPaid,
			}

			istUserStoktaked
				? stocktaked.push({ ...clientExtended, stocktaked: true })
				: notStocktaked.push({ ...clientExtended, stocktaked: false })
		})
		return {
			stocktaked,
			notStocktaked,
			data: [...stocktaked, ...notStocktaked]
		}
	}, [data])

	return {
		...queryResult,
		dataCompiled,
	}
}
