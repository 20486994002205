import { BoxProps } from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const StyledLabel = styled('span')<BoxProps>(({ theme }) => ({
	padding: 6,
	minWidth: 24,
	// display: 'block'
}))

export const StyledValue = styled('span')<any>(({ value }) => ({
	color: value > 0 ? 'red' : 'green',
	padding: 4,
	// display: 'block'
}))
