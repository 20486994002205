import dayjs from 'dayjs'
import i18n from 'i18n'

export const fullDateTime = (timestamp?: string) => timestamp ? i18n.t('time.fullDateTime', {
	date: dayjs(timestamp).format('DD/MM/YYYY'),
	time: dayjs(timestamp).format('HH:mm'),
}) : '-'

export const dateNow = () => dayjs().format('YYYY-MM-DD')

export const fullDate = (timestamp?: string) => timestamp ? dayjs(timestamp).format('YYYY-MM-DD') : '-'

export const fullTime = (timestamp?: string) => timestamp ? dayjs(timestamp).format('HH:mm') : '-'