import { FC } from 'react'
import i18next from 'i18next'
import { gql, useQuery } from '@apollo/client'
import { FormItem, OptionsProp, MuiSelect, MuiSelectProps } from '../Form'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'
import { Product } from 'types/Product'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { en, ar } from './translations'

import { calculateAmount } from 'globals/math'

const GET_PRODUCTS=gql`
	query($filterKeyword: String!) {
		products(filterKeyword: $filterKeyword) {
			id
			name
			unit
			amount
			client {
				id
				name
			}
			purchaseItems {
				amount
			}
		}
	}
`

interface ProductsApiResponse {
	products: Product[]
}

interface ProductSelectProps extends Pick<MuiSelectProps, 'size'> {
	fieldName: string
	onProceed?: () => void
}

export const ProductSelect: FC<ProductSelectProps> = ({ fieldName, onProceed, ...props }) => {
	i18next.addResourceBundle('en', 'ProductSelect', en, true, true)
	i18next.addResourceBundle('ar', 'ProductSelect', ar, true, true)
	const { t } = useTranslation('ProductSelect')
	const { t: tCommon } = useTranslation()

	const { data } = useQuery<ProductsApiResponse>(GET_PRODUCTS, { variables: { filterKeyword: '' } })
	const [{ value }] = useField<string>(fieldName)

	const unitOptions: OptionsProp[] = data?.products.map((product): any => ({
		value: product.id,
		label: product.name,
	})) || []

	const selectedProduct: Product | undefined = data?.products.find(product => product.id === value)

	return (
		<Grid container>
			<Grid item xs={6}>
				<FormItem>
					<MuiSelect
						name={fieldName}
						label={t(fieldName)}
						options={unitOptions}
						{...props}
					/>
				</FormItem>
			</Grid>
			<Grid item xs={6}>
				<Box paddingX={2}>
					{ selectedProduct &&
						<>
							<Typography>{t('merchantName')} ({selectedProduct.client.name})</Typography>
							<Typography>{t('amountAvailable')} ({
								selectedProduct.amount - calculateAmount(selectedProduct.purchaseItems)
							} {tCommon(selectedProduct.unit)})</Typography>
						</>
					}
				</Box>
			</Grid>
		</Grid>
	)
}
