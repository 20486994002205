import { gql } from '@apollo/client'

export const GET_CLIENTS_NAMES = gql`
  query($filterKeyword: String!) {
    clients(filterKeyword: $filterKeyword) {
      id
      name
    }
  }
`

export const ADD_CLIENT = gql`
  mutation($name: String!) {
    createClient(name: $name) {
      id
      name
    }
  }
`
