import { FC } from 'react'
import Dialog from '@mui/material/Dialog'
import { useTranslation } from 'react-i18next'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import { ActivityIndicator, Button } from 'components'

interface NewModalProps {
  open: boolean
  isLoading: boolean
  handleSubmit: any
  handleClose: any
  value: string
}
export const NewModal: FC<NewModalProps> = ({ open, isLoading, value, handleSubmit, handleClose }) => {
	const { t } = useTranslation('DataSelect')

	return (
		<Dialog open={open} onClose={handleClose} fullWidth>
			<DialogTitle>{t<string>('add', { clientName: value})}</DialogTitle>
			{!open || isLoading ? 
				<DialogContent sx={{ minWidth: 100, height: 96, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
					<ActivityIndicator sx={{ margin: 'auto'}} />
				</DialogContent>
				: (
					<>
						<DialogContent>
							<DialogContentText>
								{t<string>('addConfirm')}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose} color='inherit'>{t('cancel')}</Button>
							<Button onClick={handleSubmit}>{t('addButton')}</Button>
						</DialogActions>
					</>
				)}
		</Dialog>
	)}
