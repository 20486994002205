import { FC } from 'react'
import {useTranslation} from 'react-i18next'
import FacebookMessengerIcon from 'react-share/lib/FacebookMessengerIcon'
import FacebookMessengerShareButton from 'react-share/lib/FacebookMessengerShareButton'
import TelegramIcon from 'react-share/lib/TelegramIcon'
import TelegramShareButton from 'react-share/lib/TelegramShareButton'
import ViberShareButton from 'react-share/lib/ViberShareButton'
import ViberIcon from 'react-share/lib/ViberIcon'
import WhatsappShareButton from 'react-share/lib/WhatsappShareButton'
import WhatsappIcon from 'react-share/lib/WhatsappIcon'


interface ButtonsListProps {
    url: string
}
export const ButtonsList: FC<ButtonsListProps> = ({ url }) => {
	const { t } = useTranslation('ShareButton')

	return (
		<>
			<FacebookMessengerShareButton
				appId=""
				url={url}
			>
				<FacebookMessengerIcon size={32} round />
			</FacebookMessengerShareButton>
			{' '}
			<TelegramShareButton
				url={url}
				title={t<string>('subject')}
			>
				<TelegramIcon size={32} round />
			</TelegramShareButton>
			{' '}
			<ViberShareButton
				url={url}
				title={t<string>('subject')}
			>
				<ViberIcon size={32} round />
			</ViberShareButton>
			{' '}
			<WhatsappShareButton
				url={url}
				title={t<string>('subject')}
				separator="
        "

			>
				<WhatsappIcon size={32} round />
			</WhatsappShareButton>
		</>
	)
}
