import Box, { BoxProps } from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
	width: '100%',
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	textAlign: 'center',
	alignItems: 'center',
}))
