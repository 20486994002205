import { FC, useState } from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useScreenshot } from 'use-react-screenshot'
import i18next from 'i18next'
import { Image } from './Image'
import {ar, en} from './translations'

interface ShareImageButtonProps {
  containerId: string
}

export const ShareImageButton: FC<ShareImageButtonProps> = ({ containerId }) => {
	i18next.addResourceBundle('en', 'ShareImageButton', en, true, true)
	i18next.addResourceBundle('ar', 'ShareImageButton', ar, true, true)
	const [image2, takeScreenShot] = useScreenshot()

	const [image, setImage] = useState<string | undefined>()

	const getImage = () => {
		const element = document.getElementById(containerId)

		takeScreenShot(element)
	}

	return (
		<Image image={image2} getImage={getImage} />
	)
}
