import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import Button, { ButtonProps } from '@mui/material/Button'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { IconsMap } from 'components/Icons'

interface ButtonSubmitProps extends Omit<ButtonProps, 'type' | 'label'> {
  label?: string
  isLoading?: boolean
  type: 'login' | 'save'
}
export const ButtonSubmit: FC<ButtonSubmitProps> = ({ label, type, isLoading, ...props }) => {
	const { t }  = useTranslation()

	const { isSubmitting, isValid } = useFormikContext()
	const defaultLabel = type === 'login' ? t<string>('login') : t<string>('submit')
	const defaultIcon = type === 'login' ? <IconsMap.login fontSize="inherit" /> : <IconsMap.save fontSize="inherit" />
	return (
		<Box sx={{ position: 'relative', mt: 3 }}>
			<Button
				dir="ltr"
				variant="contained"
				fullWidth
				color="primary"
				disabled={isLoading || isSubmitting}
				type="submit"
				endIcon={defaultIcon}
				{...props}
			>
				{label || defaultLabel}
			</Button>
			{(isLoading || isSubmitting) && <CircularProgress size={24} sx={{
				position: 'absolute',
				top: 'calc(50% - 12px)',
				left: 'calc(50% - 24px)',
			}} />}
		</Box>


	)
}
