import { FC } from 'react'
import { IconsMap } from 'components/Icons'
import { Page } from 'components'
import Typography from '@mui/material/Typography'

export const Error: FC = () => {
	return (
		<Page centered>
			<Typography
				align="center"
				variant='h5'
			>
				<IconsMap.error />
        Error
			</Typography>
			<Typography
				align="center"
				variant="caption"
			>
        something went wrong
			</Typography>
		</Page>
	)
}
