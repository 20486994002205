

export const priceSign = (price?: number) => {
	if (isNaN(Number(price))) return '-'

	const priceAsNumber = Number(price)

	let p: string | number = priceAsNumber.toFixed(4)

	if (priceAsNumber.toFixed(4).substr(-5) === '.0000') { p = priceAsNumber }
	if (priceAsNumber.toFixed(4).substr(-2) === '00' && priceAsNumber.toFixed(4).substr(-5) !== '.0000') {
		p = priceAsNumber.toFixed(2)
	}

	const priceStringAsArray = price?.toString().split('.') || ['', '']
	const precision = priceStringAsArray[1]
	const value = priceStringAsArray[0]

	if (precision?.length > 4) {
		if (Number(precision[0]) !== 0) {
			p = Number(price).toFixed(2)
		} else {
			p = value
		}
	}

	return (
		<span dir="ltr">
			<span dir="ltr">
      $
			</span>
			<span
				// dir="rtl" // TODO: check and fix
			>
				{`${p}`}
			</span>
		</span>
	)
}
