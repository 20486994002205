import { FC } from 'react'
import { Link as LinkRouter } from 'react-router-dom'
import Link from '@mui/material/Link'
import { routes } from 'globals/routes'
import { fullDate } from 'globals/date'
import { useTranslation } from 'react-i18next'

interface PurchaseDateProps {
  id: string
  date: string
}
export const PurchaseDate: FC<PurchaseDateProps> = ({ id, date }) => {
	const { t } = useTranslation()
	return (
		<Link
			component={LinkRouter}
			to={`${routes.purchaseShow}/${id}`}>
			{t('purchases.date', { date: fullDate(date) })}
		</Link>
	)
}
