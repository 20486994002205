
// import { green } from '@mui/material/colors'

// import { makeStyles, createStyles } from '@mui/styles'

// export const useButtonWithIconStyles =  makeStyles((theme: any) => createStyles({
//   wrapper: {
//     position: 'relative',
//   },
// }))


export const useButtonWithIconStyles = (params?: any): any => ({}) as any
