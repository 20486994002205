import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import CardHeader from '@mui/material/CardHeader'
import TableBody from '@mui/material/TableBody'

import { ClientName, IconsMap, TableCell, TableRow } from 'components'
import { priceSign } from 'globals/signs'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { PurchaseItem } from 'types/PurchaseItem'

interface PurchaseItemsDetails {
  purchaseItems?: PurchaseItem[]
  showClientName?: boolean
}

type TableRowType = {
  key: string
  label?: string
  value?: string | JSX.Element
}

export const PurchaseItemsDetails: FC<PurchaseItemsDetails> = ({ purchaseItems, showClientName }) => {
	const { t } = useTranslation('purchases')
	const { t: tCommon } = useTranslation()
  
	return (
		<>
			{purchaseItems?.map((purchaseItem: PurchaseItem) => {
				const rows: TableRowType[] = [
					{
						key: 'table.amount',
						label: t<string>('table.amount'),
						value: t<string>('table.amountValue', { amount: purchaseItem.amount, unit: tCommon(purchaseItem.product.unit) })
					},
					{ key: 'table.unitPrice', label: t<string>('table.unitPrice'), value: priceSign(purchaseItem.price / purchaseItem.amount) },
					{ key: 'table.price', label: t<string>('table.price'), value: priceSign(purchaseItem.price) },
					// { key: 'table.extraCosts', label: t<string>('table.extraCosts'), value: priceSign(purchaseItem?.extraCosts) },
				]
				return (
					<TableContainer sx={{ padding: 0.2 }} key={purchaseItem.id}>
						<CardHeader
							title={
								showClientName
									?  <ClientName name={purchaseItem.purchase.client.name} id={purchaseItem.purchase.client.id} />
									: purchaseItem.product.name}
							titleTypographyProps={{ variant: 'body1', gutterBottom: true,  }}
							avatar={showClientName
								? <IconsMap.client color="action" />
								: <IconsMap.purchases color="action" />
							}/>
						<Table>
							<TableBody>
								{rows.map((row) => (
									<TableRow key={row.key}>
										<TableCell align="inherit" width={80}><div>{row.label}</div></TableCell>
										<TableCell>{row.value}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				)})}
		</>
	)
}
