import { FC } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { Page } from 'components'

export const Loading: FC = () => {
	return (
		<Page centered>
			<CircularProgress color="primary" />
		</Page>
	)
}
