import { useContext } from 'react'
import { AccountContext } from 'providers/Account'

export const useAccount = () => {
	const { account, isAccountValidating } = useContext(AccountContext)

	return {
		account,
		isAccountValidating,
	}
}
