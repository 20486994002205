import * as Yup from 'yup'
import { MixedLocale, NumberLocale, setLocale, StringLocale } from 'yup'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { mixed, number, string } from 'yup/lib/locale'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import _printValue  from 'yup/lib/util/printValue'

import i18next from 'i18next'


export const translatedMixed: MixedLocale = {
	default: ({ label }) => i18next.t('validation.mixed.default', { label }),
	required: ({ label }) => i18next.t('validation.mixed.required', { label }),
	notType: ({ path, type, originalValue }) => i18next.t(`validation.mixed.type.${type}`, { path, value: originalValue }),
}

export const translatedString: StringLocale = {
	min: ({ min, label }) => i18next.t('validation.string.min_interval',
		{ postProcess: 'interval', count: min, label }),
	max: ({ max, label }) => i18next.t('validation.string.max_interval',
		{ postProcess: 'interval', count: max, label }),
}

export const translatedNumber: NumberLocale = {
	min: ({ min, label }) => i18next.t('validation.number.min_interval',
		{ postProcess: 'interval', count: min, label }),
	max: ({ max, label }) => i18next.t('validation.number.max', { count: max, label }),
}

export const initilizeYupCustomConfigurations = () => {
	setLocale({
		mixed: { ...mixed, ...translatedMixed },
		string: { ...string, ...translatedString },
		number: { ...number, ...translatedNumber },
	})
}

initilizeYupCustomConfigurations()
