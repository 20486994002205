import { FC, ReactNode } from 'react'
import { StyledBox } from './CenteredBox.styles'


export const CenteredBox: FC<{ children: ReactNode }> = ({ children }) => {

	return (
		<StyledBox>
			{children}
		</StyledBox>
	)
}
