import { FC, ReactNode } from 'react'
import MuiTable, { TableProps as MuiTableProps } from '@mui/material/Table'
import Paper from '@mui/material/Paper'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import MuiTableRow from '@mui/material/TableRow'
import MuiTableCell from '@mui/material/TableCell'
import { TableLoading } from './TableLoading'
import { TableEmpty } from './TableEmpty'

interface TableHeader {
  label: string | JSX.Element
  key: string
}


interface TableProps extends Pick<MuiTableProps, 'sx'> {
  children: ReactNode
  loading?: boolean
  empty?: boolean
  noStyles?: boolean
  tableHeader?: TableHeader[]
}

export const Table: FC<TableProps> = ({
	children,
	loading,
	empty,
	noStyles,
	tableHeader,
	sx,
}) => {

	const UI: JSX.Element = empty ? <TableEmpty /> : (
		<TableBody>
			{children}
		</TableBody>
	)

	return (
		<>
			<TableContainer sx={{ height: '100%', padding: 0.2, ...sx }} component={noStyles ? 'div' : Paper}>
				<MuiTable
					sx={{ minWidth: 200, paddingBottom: 2 }}
					stickyHeader
				>
					{tableHeader && !loading && <TableHead>
						<MuiTableRow sx={noStyles ? { background: 'none' } : {
							'& th': { textAlign: 'center', paddingY: 0, paddingX: 0.25 },
							width: '100%',
							position: 'sticky',
							fontWeight: 'bold',
							zIndex: 2,
						}}>
							{tableHeader.map((headerItem) => (
								<MuiTableCell
									key={headerItem.key}
									sx={ noStyles
										? ({ fontWeight: 'normal' })
										: ({ fontWeight: 'bold' })}>
									{headerItem.label}
								</MuiTableCell>
							))}
						</MuiTableRow>
					</TableHead>}
					{loading
						? <TableLoading />
						: UI
					}
				</MuiTable>
			</TableContainer>
		</>
	)
}
