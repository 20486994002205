import Chip, { ChipProps } from '@mui/material/Chip'
import { purple, brown, green, orange } from '@mui/material/colors'
import { styled } from '@mui/material/styles'

export const StyledChip = styled(Chip)<ChipProps>(({ theme, label }) => {
  
	let background: string

	background = brown[500]

	if (label === 'عزيز') background = green[500]
	if (label === 'نبي') background = purple[500]
	if (label === 'عزالدين') background = orange[500]

	return ({
		borderRadius: theme.spacing(0.5),
		width: 'auto',
		margin: theme.spacing(0.2, 0),
		height: 'auto',
		fontSize: '0.7rem',
		background,
		textAlign: 'center',
		lineHeight: '1rem',
		color: theme.palette.common.white,
	})
})
