import { gql } from '@apollo/client'

export const GET_PURCHASES=gql`
query($stocktaked: Boolean) {
  purchases(stocktaked: $stocktaked) {
    id
    stocktaked
    client {
      id
      name
    }
    purchaseItems {
        id
        product {
          id
          name
          unit
        }
    }
    createdAt
    createdBy
  }
}
`
