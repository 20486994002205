import { useQuery, useMutation } from '@apollo/client'
import { Creditor } from 'types/Creditor'
import { GET_CREDITORS, GET_CREDITORS_NAMES, ADD_CREDITOR } from './useCreditors.gql'

interface AddCreditorMutation{
  createCreditor: Creditor
}
export const useCreditors = () => {
	const [createCreditor] = useMutation<AddCreditorMutation, {name: string}>(ADD_CREDITOR)

	const getCreditors = () =>
		useQuery<{ creditors: Creditor[] }>(GET_CREDITORS, { variables: { filterKeyword: '' }})
  
	const getCreditorsNames = () => 
		useQuery<{ creditors: Creditor[] }>(GET_CREDITORS_NAMES, { variables: { filterKeyword: '' }})

	return {
		getCreditors,
		getCreditorsNames,
		createCreditor,
	}
}
