import { gql } from '@apollo/client'

export const GET_CREDITORS_NAMES=gql`
  query($filterKeyword: String!) {
    creditors(filterKeyword: $filterKeyword) {
      id
      name
    }
  }
`

export const GET_CREDITORS=gql`
  query($filterKeyword: String!) {
    creditors(filterKeyword: $filterKeyword) {
      id
      name
      createdBy
      updatedBy
      balances {
        id
        paid
        createdAt
        createdBy
        notes
      }
    }
  }
`

export const ADD_CREDITOR = gql`
  mutation($name: String!) {
    createCreditor(name: $name) {
      id
      name
    }
  }
`
