import { FC, ReactNode } from 'react'
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import { useDialogStyles } from './Dialog.styles'
import { useTranslation } from 'react-i18next'

interface Props extends Pick<MuiDialogProps, 'sx'> {
  children: ReactNode
  open: boolean
  handleClose: () => void
  name?: string
}

export const Dialog: FC<Props> = ({ open, handleClose, name, children, sx }) => {
	const { t } = useTranslation('balanceDialog')
	const classes = useDialogStyles()

	return (
		<MuiDialog classes={classes} onClose={handleClose} open={open} sx={sx}>
			<DialogTitle>{t('dialog.title', { name })}</DialogTitle>
			<DialogContent>
				{children}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="inherit">
					{t('close')}
				</Button>
			</DialogActions>
		</MuiDialog>
	)
}
