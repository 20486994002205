
// import { makeStyles, createStyles } from '@mui/styles'

// export const useDialogStyles =  makeStyles<any>((theme: any) =>
//   createStyles({
//     root: {
//       marginTop: theme.spacing(6),
//       padding: theme.spacing(1),
//     },
//     paper: {
//       width: theme.spacing(44),
//       height: theme.spacing(42),
//     }
//   })
// )

export const useDialogStyles = (params?: any): any => ({}) as any
