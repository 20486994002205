import { FC } from 'react'
import Chip from '@mui/material/Chip'

interface StatusIndicatorProps {
  state?: boolean
  labelTrue: string
  labelFalse: string
}

export const StatusIndicator: FC<StatusIndicatorProps> = ({ state, labelTrue, labelFalse }) => {
	return (
		<Chip
			color={state ? 'success' : 'error'}
			sx={{ height: 20, fontSize: '0.7rem', marginY: 0.5 }}
			label={state ? labelTrue : labelFalse}
		/>
	)
}
