import { FC } from 'react'

interface SpinnerProps {
  size?: 'sm' | 'lg'
}

export const Spinner: FC<SpinnerProps> = ({ size = 'lg' }) => {
	return (
		<div className={`spinner-border spinner-border-${size}`} role="status" />
	)
}