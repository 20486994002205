import { FC, useState } from 'react'

import List from '@mui/material/List/List'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'

import { ButtonWithIcon } from '../ButtonWithIcon'
import {useTranslation} from 'react-i18next'
import i18next from 'i18next'
import {ar, en} from './translations'
import { IconsMap } from '../Icons'
import {gql, useMutation} from '@apollo/client'
import {ActivityIndicator} from '../ActivityIndicator'
import { generateInvoiceUrl } from '../../globals/routes'
import { ButtonsList } from './ButtonsList'
import {InvoiceType} from '../../types'

const CREATE_PUBLIC_SESSION_MUTATION = gql`
	mutation {
		createPublicSession {
				id
				token
				expiresOn
		}
	}
`

interface CreatePublicSession {
	createPublicSession: {
		id: string
		token: string
	}
}

interface ShareButtonProps {
		type: InvoiceType
		id?: string
		clientName?: string
		createdAt?: string
}

export const ShareButton: FC<ShareButtonProps> = ({ type, id, clientName, createdAt }) => {
	i18next.addResourceBundle('en', 'ShareButton', en, true, true)
	i18next.addResourceBundle('ar', 'ShareButton', ar, true, true)

	const [createPublicSession, { loading, data }] = useMutation<CreatePublicSession>
	(CREATE_PUBLIC_SESSION_MUTATION)

	const { t } = useTranslation('ShareButton')
	const [open, setOpen] = useState(false)
	const [copied, setCopied] = useState(false)
	const handleClose = () => {
		setOpen(false)
	}
	const handleOpen = () => {
		setOpen(true)
	}

	const generatedUrl = generateInvoiceUrl(
		data?.createPublicSession.token || 'NOT_PROVIDED',
		id || '',
		type
	)
	const copyCodeToClipboard = () => {
		setCopied(false)
		navigator?.clipboard?.writeText(generatedUrl)
			.finally(() => {
				setCopied(true)
			})
	}

	const handleApiRequest = async () => {
		if (loading || data) return
		await createPublicSession()
	}
	const CTA: JSX.Element =
			<>
				<List>
					{t('cta')}
				</List>
				<Box>
					{t('clientName', { name: clientName })}
					<br /><br />
					<br />
				</Box>

				<List>
					{<ButtonWithIcon
						onClick={handleApiRequest}
						label={t<string>('getUrl')}
						color="secondary"
						icon={loading ? <ActivityIndicator /> : <IconsMap.link fontSize="inherit"/>}
					/>}
				</List>
			</>

	const Share: JSX.Element = (
		<>
			<Box sx={{ color: ''}}>
				<Link href={generatedUrl} rel="noreferrer"  target='_blank'>{t('link')}</Link>
			</Box>
			<br /><br />
			<ButtonsList url={generatedUrl} />
			<ButtonWithIcon
				label={t<string>(!copied ? 'copy' : 'copied')}
				color="primary"
				onClick={copyCodeToClipboard}
			/>
		</>
	)

	return (
		<>
			<Dialog onClose={handleClose} open={open}>
				<DialogTitle>{t(`title.${type}`)}</DialogTitle>
				<DialogContent>
					{data ? Share : CTA}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="inherit">
						{t('close')}
					</Button>
				</DialogActions>
			</Dialog>
			<div>
				<ButtonWithIcon
					label={t<string>('invoice')}
					icon={<IconsMap.link fontSize="inherit" />}
					onClick={handleOpen}
					color='info'
				/>
			</div>
		</>
	)
}
