import { gql } from '@apollo/client'

export const GET_CREDITOR=gql`
  query Creditor($creditorId: String!) {
    creditor(id: $creditorId) {
      id
      name
      updatedBy
      createdBy
      balances {
        id
        notes
        paid
        updatedAt
        updatedBy
        createdBy
        createdAt
      }
    }
  }
`
