
// import { makeStyles, createStyles } from '@mui/styles'

// export const useTableEmptyStyles =  makeStyles((theme: any) => createStyles({
//   loading: {
//     background: 'none !important',
//     textAlign: 'center'
//   }
// }))

export const useTableEmptyStyles = (params?: any): any => ({}) as any
