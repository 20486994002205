import { useState, Fragment } from 'react'
import TextField from '@mui/material/TextField'
import { ActivityIndicator } from 'components'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { NewModal } from './NewModal'
import { useTranslation } from 'react-i18next'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { useField } from 'formik'

interface NameSelectEntity {
  inputValue?: string
  id?: string
  name?: string
}

const filter = createFilterOptions<NameSelectEntity>({
	stringify: (option) => option.name || '',
	ignoreAccents: true,
	ignoreCase: true,
	// limit: 20,
})

interface NameSelectProps<T> {
  fieldName: string
  placeholder: string
  label: string
  loading: boolean
  createCallback: (name: string, setState: any) => void
  onProceed?: () => void
  data: T[] | undefined
}

export function DataSelect<T extends NameSelectEntity>(props: NameSelectProps<T>) {
	const { createCallback, fieldName, data, loading, label, placeholder, onProceed } = props
 
	const { t } = useTranslation('DataSelect')

	const [{ value }, { error, touched }, { setValue, setTouched }] = useField<string>(fieldName)
  
	const [newClient, setNewClient] = useState('')
	const [open, toggleOpen] = useState(false)
	const [creatingClient, setIscreatingClient] = useState(false)
  
	const updateValue = (value: string) => {
		setValue(value)
		onProceed && onProceed()
	}
	const handleClose = () => {
		toggleOpen(false)
		return
	}

	const handleSubmit = () => {
		setIscreatingClient(true)
		createCallback(newClient, (id: string) => {
			updateValue(id)
			setIscreatingClient(false)
			toggleOpen(false)
		})
	}

	return (
		<Fragment>
			<NewModal
				open={open}
				isLoading={creatingClient}
				handleSubmit={handleSubmit}
				handleClose={handleClose}
				value={newClient}
			/>

			<Autocomplete
				value={data?.find(i => i.id === value ) || undefined}
				onChange={(_event, newValue) => {
					setTouched(true)
					if (newValue?.inputValue) {
						setNewClient(newValue?.inputValue)
						toggleOpen(true)
						return
					}
          
					if (newValue?.id) updateValue(newValue.id)
					if (newValue === null) updateValue('')
				}}
				filterOptions={(options, params) => {
					const filtered = filter(options, params)
          
					// when no data is present & no data is written
					if (data && data?.length === 0 && !params.inputValue) return [{}]

					if (filtered.length === 0) {
						return [
							{
								inputValue: params.inputValue,
								name: t<string>('add', { clientName: params.inputValue}),
							}
						]
					}

					return filtered
				}}

				options={data?.slice().filter(a => ({ key: a.id, id: a.id, name: a.name})) as NameSelectEntity[]|| []}
				getOptionLabel={(option) => {
					if (typeof option === 'string') return option
					if (option.inputValue) return option.inputValue

					return option.name || ''
				}}
				selectOnFocus
				clearOnBlur
				// clearIcon={false}
				handleHomeEndKeys
				// open
				renderOption={(props, option) => <li {...props} key={`4_${option.id}`}>{option.name}</li>}
				popupIcon={loading ? <ActivityIndicator /> :    <ArrowDropDownIcon /> }
				size='small'
				noOptionsText=''
				disabled={loading}
				renderInput={(params) => (
					<TextField
						{...params}
						label={label}
						placeholder={placeholder}
						name={fieldName}
						variant="filled"
						color="primary"
						sx={{ marginBottom: 2 }}
						error={!!(error && touched)}
						helperText={(error && touched)  && error}
					/>
				)}
			/>
		</Fragment>
	)
}
