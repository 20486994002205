import { FC } from 'react'
import Chip from '@mui/material/Chip'
import { useTranslation } from 'react-i18next'

interface StocktakedLabelProps {
  name?: string
}

export const StocktakedLabel: FC<StocktakedLabelProps> = ({ name }) => {
	const { t } = useTranslation()

	return (
		<Chip
			sx={{
				borderRadius: 1,
				paddingY: .1,
				height: 'auto',
				fontSize: '0.9rem',
			}}
			label={`${t('stocktaked')}`}
			size="small"
			color="primary"
		/>
	)
}
