import { Table, TableCell, TableRow } from 'components'
import { priceSign } from 'globals/signs'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Product } from 'types/Product'
import { fullDate } from 'globals/date'
import i18next from 'i18next'
import { en, ar } from './translations'
import { ProductName } from 'components/ProductName'

interface ProductDetailsProps {
  product: Product
}

export const ProductDetails: FC<ProductDetailsProps> = ({ product }) => {
	i18next.addResourceBundle('en', 'ProductDetails', en, true, true)
	i18next.addResourceBundle('ar', 'ProductDetails', ar, true, true)

	const { t } = useTranslation('ProductDetails')
	const { t: tCommon } = useTranslation()

	const rows = product ? [
		{ key: 'name', label: t<string>('name'), value: <ProductName name={product.name} id={product.id} />},
		{ key: 'amount', label: t<string>('amount'),
			value: `${product.amount} ${tCommon(`${product.unit}`)}`,
		},
		{ key: 'unitPrice', label: t<string>('unitPrice', {unit: tCommon(`${product.unit}`)}), value: priceSign(product.unitPrice || 0) },
		{ key: 'price', label: t<string>('price'), value: product && priceSign(product.unitPrice * product.amount) },
		// { key: 'extraCosts', label: t<string>('extraCosts'), value: priceSign(calculateExtraCosts(product.extraCosts)) },
		{ key: 'paid', label: t<string>('paid'), value: priceSign(product.paid) },
		{ key: 'createdAt', label: t<string>('createdAt'), value: fullDate(product.createdAt) },
		{ key: 'updatedAt', label: t<string>('updatedAt'), value: fullDate(product.updatedAt) },
	] : []

	return (
		<Table noStyles>
			{rows.map((row) => (
				<TableRow key={row.key}>
					<TableCell width={90}><div>{row.label}</div></TableCell>
					<TableCell><>{row.value}</></TableCell>
				</TableRow>
			))}
		</Table>
	)
}
