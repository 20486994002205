import { PaletteMode, Theme } from '@mui/material/index'
import { indigo, grey } from '@mui/material/colors'
import { CustomTheme } from 'globals/types'

const palette = {
	light: {
		primary: {
			main: '#34C0AC',
			light: '#B1DED3',
			dark: '#00765A',
		},
	},
	secondary: {
		main: '#121858',
		light: '#311b92',
		dark: '#221266',
	},
	background: {
		dark: grey[900],
		light: grey[100],
	}
}

export const getDesignTokens = (mode: PaletteMode, customColors?: CustomTheme): Theme => ({
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	palette: {
		mode,
		...(mode === 'light'
			? {
				bg: {
					main: palette.background.light,
					light: '#d0d4ed',
					dark: '#191919',
				},
				primary: {
					main: indigo[500],
					light: indigo[400],
					dark: indigo[600],
				},
				secondary: {
					main: palette.secondary.light,
					light: palette.secondary.light,
					dark: palette.secondary.dark,
				},
				...customColors?.light
			}
			: {
				primary: {
					main: indigo[500],
					light: indigo[400],
					dark: indigo[600],
				},
				secondary: {
					main: palette.secondary.light,
					light: palette.secondary.light,
					dark: palette.secondary.dark,
				},
				bg: {
					main: palette.background.dark,
					light: '#191919',
					dark: '#d0d4ed',
				},
				...customColors?.dark
			}),
	},
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	typography: {
		fontFamily: 'Dubai',
		body1: {
			fontFamily: 'Dubai',
		},
		body2: {
			fontFamily: 'Dubai',
		},
	},
})
