import { createContext, FC, ReactNode, useCallback, useMemo, useState } from 'react'
import { PaletteMode, CssBaseline } from '@mui/material/index'
import { ThemeProvider as MUIThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles'
import GlobalStyles from './globalStyles'
import { getDesignTokens } from './theme2'
import { CustomTheme } from 'globals/types'

export interface ThemeProps {
  toggleColorMode?: () => void
  setTheme: (theme: CustomTheme) => void
  theme?: CustomTheme
}

export const ThemeContext = createContext<ThemeProps>({
	toggleColorMode: () => undefined,
	setTheme: () => undefined,
	theme: { dark: {}, light: {}},
})


export const ThemeProvider: FC<{   children?: ReactNode }> = ({ children }) => {
	const savedTheme = window.localStorage.getItem('theme')
	const [mode, setMode] = useState<PaletteMode>(savedTheme === 'dark' ? 'dark' : 'light')
	const [customTheme, setCustomTheme] = useState<CustomTheme | undefined>()

	const toggleColorMode = useCallback(() => {
		const newMode = mode === 'light' ? 'dark' : 'light'
  
		setMode(newMode)
		window.localStorage.setItem('theme', newMode)
	}, [mode])

	const theme = useMemo(
		() => createTheme(getDesignTokens(mode, customTheme)),
		[mode, customTheme]
	)

	return (
		<ThemeContext.Provider value={{ toggleColorMode, theme: { dark: {}, light: {}}, setTheme: setCustomTheme }}>
			<StyledEngineProvider injectFirst>
				<MUIThemeProvider theme={theme}>
					<CssBaseline />
					<GlobalStyles />
					{children}
				</MUIThemeProvider>
			</StyledEngineProvider>
		</ThemeContext.Provider>
	)
}
