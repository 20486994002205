import { FC } from 'react'
import Paper from '@mui/material/Paper'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import CardHeader from '@mui/material/CardHeader'

import { useDetailsTableStyles } from './DetailsTable.styles'
import { IconsMap, TableCell, TableRow } from 'components'


type TableRowType = {
  key: string
  label?: string | JSX.Element
  value?: string | JSX.Element
}
interface DetailsTableProps {
  header?: string | JSX.Element
  action?: JSX.Element
  rows: TableRowType[]
  noStyles?: boolean
}

export const DetailsTable: FC<DetailsTableProps> = ({
	header,
	action,
	rows,
	noStyles,
}) => {
	const classes = useDetailsTableStyles()

	return (
		<TableContainer id='_details'  sx={{ padding: 0.2, overflow: 'auto' }} className={classes.container} component={noStyles ? 'div' : Paper}>
			{header &&<CardHeader
				title={header}
				action={action}
				titleTypographyProps={{ variant: 'body1', gutterBottom: true,  }}
				avatar={<IconsMap.details />}
			/>}
			<Table>
				<TableBody>
					{rows.map((row) => (
						<TableRow key={row.key}>
							<TableCell className={classes.labelCell} align="inherit" width={90}><div>{row.label}</div></TableCell>
							<TableCell>{row.value}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}
