
// import { makeStyles, createStyles } from '@mui/styles'

// export const useDeleteButtonStyles =  makeStyles((theme: any) => createStyles({
//   root: {
//     background: theme.palette.error.main,
//     color: theme.palette.common.white,
//     '&:hover': {
//       color: theme.palette.error.dark,
//     },
//   },
// }))


export const useDeleteButtonStyles = (params?: any): any => ({}) as any
