import { gql } from '@apollo/client'

export const GET_PURCHASE=gql`
query($id: String!) {
  purchase(id: $id) {
    id
    notes
    paid
    createdBy
    updatedBy
    stocktaked
    driverName
    driverPhone
    purchaseItems {
      id
      amount
      price
      extraCosts
      product {
        id
        name
        unit
      }
    }
    client {
      id
      name
    }
    createdAt
    updatedAt
  }
}
`
