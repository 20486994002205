import { FC, useState, forwardRef } from 'react'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import {useTranslation} from 'react-i18next'
import { ButtonWithIcon } from '../ButtonWithIcon'
import { IconsMap } from '../Icons'
import Box from '@mui/material/Box'

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
    children: React.ReactElement;
  },
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />
})

interface ImageProps { image?: string, getImage: () => void }

export const Image: FC<ImageProps> = ({ image, getImage }) => {
	const { t } = useTranslation('ShareImageButton')
	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		getImage()
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<div>
			<ButtonWithIcon
				label={t<string>('imageLink')}
				icon={<IconsMap.image fontSize="inherit" />}
				onClick={handleClickOpen}
				color='info'
			/>
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
			>
				<AppBar sx={{ position: 'relative' }}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleClose}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<Box display='flex' height='60%'>
					<Box width='100%' padding={2} margin='auto' sx={{ backgroundColor: '#00000020' }}>
						<img  width='100%' src={image} />
					</Box>
				</Box>
			</Dialog>
		</div>
	)
}
