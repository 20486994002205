import { FC } from 'react'
import { useFormikContext, useField } from 'formik'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { FormItem } from '../FormItem'
import { IconsMap } from 'components/Icons'

interface MuiInputProps extends Omit<TextFieldProps, 'name'> {
	name: string // required
	priceIcon?: boolean
	label?: string
	required?: boolean
	suffix?: JSX.Element
}

export const MuiInput: FC<MuiInputProps> = ({ name, required, suffix, priceIcon, type, ...props }) => {
	const  { isSubmitting, handleChange, handleBlur } = useFormikContext()
	const [{ value }, { error, touched }, { setValue }] = useField<string>(name)
	const handleOnBlur: any = (e: any) => {
		if (!e) return
		e.persist = () => undefined
		value && setValue(value)
		handleBlur(e)
	}

	const handleOnChange: any = (e: any) => {
		handleChange(e)
	}

	return (
		<FormItem>
			<TextField
				name={name}
				id={name}
				type={type}
				fullWidth
				disabled={isSubmitting}
				onChange={handleOnChange}
				onBlur={handleOnBlur}
				required={required}
				value={value}
				autoComplete="off"
				variant="filled"
				color="primary"
				sx={{ marginBottom: 2 }}
				error={!!(error && touched)}
				helperText={(error && touched)  && error}
				InputProps={priceIcon ? {
					startAdornment: (
						<>
							{priceIcon && <IconsMap.moneySign fontSize="inherit" color="action" />}
						</>
					),
				} : undefined}
				{...props}
			/>
		</FormItem>
	)
}
