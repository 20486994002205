import { useQuery } from '@apollo/client'
import { Creditor } from 'types/Creditor'
import { useParams } from 'react-router-dom'
import { GET_CREDITOR } from './useCreditor.gql'

export const useCreditor = () => {
	const { id } = useParams<{ id: string }>()

	const getCreditor = () => useQuery<{ creditor: Creditor }>(GET_CREDITOR, {variables: { creditorId: id } })

	return { getCreditor }
}
