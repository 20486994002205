import { FC, ReactNode } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button'
import Box  from '@mui/material/Box'

interface ButtonProps extends MuiButtonProps {
  children: ReactNode
  isLoading?: boolean
}

export const Button: FC<ButtonProps> = ({ children, isLoading, disabled, ...props }) => {
	return (
		<Box sx={(theme) => ({
			position: 'relative',
			minWidth: theme.spacing(15),
			marginX: 1
		})}>
			<MuiButton
				variant="contained"
				fullWidth
				dir='ltr'
				color="primary"
				type="submit"
				{...props}
				disabled={isLoading || disabled}
				startIcon={!isLoading && props.startIcon}
			>
				{children}
				{isLoading && <CircularProgress size={24} sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					marginTop: -1.5,
					marginLeft: -1.5,
				}} />}
			</MuiButton>
		</Box>


	)
}
