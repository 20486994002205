import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { MuiInput, ButtonSubmit } from 'components'
import Box from '@mui/material/Box'

export const BalanceForm: FC = () => {
	const { t } = useTranslation('balanceDialog')

	return (
		<>
			<MuiInput name="paid" label={t<string>('paid')} suffix={<>$</>}/>
			<MuiInput name="notes" label={t<string>('notes')} />
			<Box sx={{ marginTop: 2 }}>
				<ButtonSubmit type="save" />
			</Box>
		</>
	)
}
