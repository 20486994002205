import { FC } from 'react'
import { Link as LinkRouter } from 'react-router-dom'
import Link from '@mui/material/Link'
import { routes } from 'globals/routes'

interface ProductNameProps {
  id: string
  name: string
}
export const ProductName: FC<ProductNameProps> = ({ id, name }) => {
	return (
		<Link
			component={LinkRouter}
			to={`${routes.productShow}/${id}`}>
			{name}
		</Link>
	)
}
