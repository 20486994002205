
import { Spinner } from '../Spinner'

import './Loading.css'

export function Loading() {
	return (
		<div className="loading-wrapper">
			<Spinner />
		</div>
	)
}