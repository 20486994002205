import { ProductExtraCost } from 'types/ProductExtraCost'
import { PurchaseItem } from 'types/PurchaseItem'
import { ClientPayment } from 'types/ClientPayment'
import { Product } from 'types/Product'
import { CreditorBalance } from 'types/CreditorBalance'

export const add = (a: number, b: number): number =>
	parseFloat((a + b).toFixed(4))

export const substract = (a: number, b: number): number =>
	parseFloat((a - b).toFixed(4))

export const multiply = (a: number, b: number): number =>
	parseFloat((a * b).toFixed(4))

export const devide = (top: number, bottom: number) => multiply(top, bottom) ? Number((top / bottom).toFixed(4)) : 0

export const calculateExtraCostsV1 = (purchaseItems?: PurchaseItem[]) =>
	Number(Number(purchaseItems?.reduce((a: number, b: PurchaseItem) => add(a, b.extraCosts), 0)).toFixed(4) || 0)

export const calculateSum = (purchaseItems?: PurchaseItem[]) =>
	Number(Number(purchaseItems?.reduce((a: number, b: PurchaseItem) => add(a, b.price), 0)).toFixed(4) || 0)

export const calculateAmount = (purchaseItems?: PurchaseItem[]) =>
	Number(Number(purchaseItems?.reduce((a: number, b: PurchaseItem) => add(a, b.amount), 0)).toFixed(4) || 0)

export const calculateValues = (dataSet?: {value: number}[]) =>
	Number(Number(dataSet?.reduce((a: number, b: {value: number}) => add(a, b.value), 0)).toFixed(4) || 0)

export const calculatePurchaseDebt = (dataSet?: ClientPayment[]) =>
	Number(Number(dataSet?.reduce((a: number, b: ClientPayment) => add(substract(a, b.price), b.paid), 0)).toFixed(4) || 0)

export const calculateBalance = (dataSet?: {paid: number}[]) =>
	Number(Number(dataSet?.reduce((a: number, b: {paid: number}) => add(a, b.paid), 0)) || 0)

export const calculateCreditorBalance = (balances?: CreditorBalance[]) =>
	Number(Number(balances?.reduce((a: number, b: {paid: number}) => add(a, b.paid), 0)) || 0)

// v2 function
export const calculateProductDebt = (dataSet?: {price: number }[]) =>
	Number(Number(dataSet?.reduce((a: number, b: { price: number }) => add(a, b.price), 0)).toFixed(4) || 0)
// -----------
export const calculateProductPaid = (dataSet?: Product[]) =>
	Number(Number(dataSet?.reduce((a: number, b: { paid: number }) => add(a, b.paid), 0)).toFixed(4) || 0)
// -----------

export const calculateProductDebtWithExtraCosts = (dataSet?: {price: number, extraCosts: number}[]) =>
	Number(Number(dataSet?.reduce((a: number, b: { price: number, extraCosts: number }) => add(add(a, b.price), b.extraCosts), 0)).toFixed(4) || 0)
// -----------



// v2 functions
export const calculateExtraCosts = (data?: ProductExtraCost[]) =>
	Number(Number(data?.reduce((a: number, b: {paid: number}) => add(a, b.paid), 0)).toFixed(4) || 0)

export const calculatePurchaseItemsValue = (data?: PurchaseItem[]) =>
	Number(Number(data?.reduce((a: number, b: {price: number}) => add(a, b.price), 0)).toFixed(4) || 0)

export const calculatePurchaseItemsExtraCosts = (data?: PurchaseItem[]) =>
	Number(Number(data?.reduce((a: number, b: {extraCosts: number}) => add(a, b.extraCosts), 0)).toFixed(4) || 0)

export const calculatePurchaseItemsTotalSum = (data?: PurchaseItem[]) =>
	Number(Number(data?.reduce((a, b) => {
		const calculatedPrice = multiply(b.amount, b.unitPrice)
		return add(a, calculatedPrice)
	}, 0)).toFixed(4) || 0)


