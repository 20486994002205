import { Balance } from 'types/Balance'
import { Purchase } from 'types/Purchase'
import { ClientPayment } from 'types/ClientPayment'
import { Product } from 'types/Product'

export const createBalancesReview = (balances?: Balance[]): ClientPayment[] =>
	balances?.map(balance => ({
		id: balance.id,
		price: 0,
		paid: balance.paid,
		extraCosts: 0,
		date: balance.createdAt,
		type: 'balance',
		createdBy: balance.createdBy,
		checkpoint: false,
	})) || []

export const createPurchasesReview = (purchases?: Purchase[]): ClientPayment[] =>
	purchases?.map(purchase => ({
		id: purchase.id,
		price: purchase.purchaseItems.reduce((a, b) => a + b.price, 0),
		paid: purchase.paid,
		extraCosts: 0,
		date: purchase.createdAt,
		createdBy: purchase.createdBy,
		type: 'purchase'
	})) || []

export const createProductsReview = (purchases?: Product[]): ClientPayment[] =>
	purchases?.map(purchase => ({
		id: purchase.id,
		name: purchase.name,
		price: purchase.amount * purchase.unitPrice,
		paid: purchase.paid,
		extraCosts: purchase?.extraCosts?.reduce((a, b) => a + b.paid, 0) || 0,
		date: purchase.createdAt,
		createdBy: undefined,
		type: 'product'
	})) || []
