import { FC, useState } from 'react'
import { FetchResult } from '@apollo/client'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import IconButton from '@mui/material/IconButton'

import { ActivityIndicator, IconsMap } from 'components'
import { useTranslation } from 'react-i18next'
import { useDeleteButtonStyles } from './DeleteButton.styles'
import { useSnackbar } from 'hooks/useSnackbar'
import { routes } from 'globals/routes'
import { useNavigate } from 'react-router-dom'

interface DeleteButtonProps {
  id?: string
  loading?: boolean
  deleteHandler: (props: any) => Promise<FetchResult<any, Record<string, any>>>
}

export const DeleteButton: FC<DeleteButtonProps> = ({ id, loading, deleteHandler }) => {
	const [open, setOpen] = useState(false)
	const history = useNavigate()
	const { t } = useTranslation()
	const classes = useDeleteButtonStyles()
	const { notify } = useSnackbar()

	const handleDelete = () => {
		deleteHandler({ variables:  { id } })
			.then(() => {
				notify(t('delete.success'))
				history(routes.purchases)
			})
			.catch((error) => {
				console.error({ error })
				notify(t('delete.failed'))
			}).finally(() => {
				handleClose()
			})
	}
	const handleClickOpen = () => {
		if (!id || loading) return
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
			<IconButton onClick={handleClickOpen} color="inherit">
				{(!id || loading || open) ? <ActivityIndicator /> : <IconsMap.delete />}
			</IconButton>
			<div>
				<Dialog
					open={open}
					onClose={handleClose}
					onBackdropClick={handleClose}
				>
					<DialogTitle>{t('delete.title')}</DialogTitle>
					<DialogContent>
						<DialogContentText>{t('delete.dialog')}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => { handleClose() }} color="secondary" autoFocus>
							{t('delete.cancel')}
						</Button>
						<Button classes={classes} onClick={handleDelete}>
							{t('delete.confirm')}
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		</>
	)
}
