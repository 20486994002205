import { useQuery } from '@apollo/client'
import { Product } from 'types/Product'
import {
	GET_PRODUCTS,
	GET_STOCKTAKED_PRODUCTS,
	GET_PRODUCTS_LIST,
} from './useProducts.gql'

interface ProductsResponse {
  products: Product[]
}

export const useProducts = () => {
	const getProducts = () => useQuery<ProductsResponse>(
		GET_PRODUCTS,
		{ variables: { showUnavailable: true, filterKeyword: '', stocktaked: false }
		})

	const getProductsList = () => useQuery<ProductsResponse>
	(GET_PRODUCTS_LIST, { variables: { filterKeyword: '' }})

	const getStocktakedProducts = () => useQuery<ProductsResponse>(
		GET_STOCKTAKED_PRODUCTS,
		{ variables: { showUnavailable: true, filterKeyword: '', stocktaked: true }}
	)

	return { getProducts, getProductsList, getStocktakedProducts }

}
