import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { LoadingBlink} from 'components'
import MuiInput from '@mui/material/Input'
import './SearchInput.css'

interface SearchInputProps {
  onChange: (searchKey: string) => void
  value: string
  count?: number
  isActive?: boolean
  isLoading?: boolean
}

export const SearchInput: FC<SearchInputProps> = ({
	onChange,
	count,
	isLoading,
	isActive,
	value
}) => {
	const { t } = useTranslation()
	return (
		<section>
			<div className="input-group mb-3">
				<MuiInput
					id="search-input"
					name="search-input"
					type="text"
					className="form-control"
					placeholder={t<string>('searcPlaceholder')}
					onChange={(e: any) => {
						onChange(e.target.value)
					}}
					value={value}
				/>
				<div className="search-bar-extra search-bar-icon-wrapper">
					{isActive && (isLoading ? <LoadingBlink /> : count)}
				</div>
			</div>
		</section>
	)
}
