import { FC } from 'react'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'

import { Balance } from 'types/Balance'
import { CreditorBalance } from 'types/CreditorBalance'
import { PriceWithColor, EditorTag, IconsMap } from 'components'
import { fullDate } from 'globals/date'

type BalanceG = Balance | CreditorBalance
interface BalanceListProps {
  balances?: BalanceG[]
}

export const BalanceList: FC<BalanceListProps> = ({ balances }) => {
	return (
		<List>
			<List dense>
				{balances?.map((balance) =>
					<ListItem
						key={balance.id}
						dir='ltr'
					>
						<ListItemAvatar>
							<Box display='flex' flexDirection='column' marginTop={-1}>
								<Avatar variant="rounded" sx={{ height: 26, width: 26, marginX: 'auto' }}>
									<IconsMap.balanceNew />
								</Avatar>
								<EditorTag name={balance.createdBy} />
							</Box>
						</ListItemAvatar>
						<ListItemText
							dir='inherit'
							primary={<PriceWithColor value={balance.paid} />}
							secondary={
								<Box sx={{ padding: 1}} component='span' display='block'>
									<Typography
										dir='rtl'
										sx={{ textAlign: 'justify' }}
										textAlign='left'
										variant="body1"
										component='span'
										display='block'
									>
										{balance.notes}
									</Typography>
									<Typography variant="body2" component='span'>
										{fullDate(balance.createdAt)}
									</Typography>
								</Box>
							} 
						/>
					</ListItem>
				)}
			</List>
		</List>
	)
}

