
import { useTableEmptyStyles } from './TableEmpty.styles'
import { TableRow } from '../TableRow'
import { TableCell } from '../TableCell'
import TableBody from '@mui/material/TableBody'

import { useTranslation } from 'react-i18next'
export const TableEmpty = () => {
	const { t } = useTranslation()

	const classes = useTableEmptyStyles()
	return (
		<TableBody>
			<TableRow>
				<TableCell colSpan={30} className={classes.loading}>
					{t('emptyTable')}
				</TableCell>
			</TableRow>
		</TableBody>
	)
}
