import { FC, createContext, ReactNode } from 'react'

export interface PageProps {
  pageTitle: string
  pageIcon: JSX.Element
}

export const PageContext = createContext<PageProps>({
	pageTitle: '',
	pageIcon: <></>,
})

interface PageProviderProps {
  children: ReactNode
  pageTitle: string
  pageIcon: JSX.Element
}
export const PageProvider: FC<PageProviderProps> = ({ children, pageTitle, pageIcon }) => {
	return (
		<PageContext.Provider value={{ pageTitle, pageIcon }}>
			{children}
		</PageContext.Provider>
	)
}
