import { FC, ReactNode, Fragment } from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { IconsMap } from 'components/Icons'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { Link } from 'react-router-dom'

export type ActionButtonItemType = {
  key: string
  icon?: JSX.Element
  onClick?: () => void
  disabled?: boolean
  url?: string
  component?: JSX.Element
}

export interface ContentHeaderProps {
  children: ReactNode
  backUrl?: string
  actionButtons?: ActionButtonItemType[]
  pageTitle?: string
  pageIcon: JSX.Element
  title?: string
}

export const ContentHeader: FC<ContentHeaderProps> = ({
	backUrl,
	actionButtons,
	pageTitle,
	pageIcon,
	title,
	children,
}) => {
  
	const ActionLink: FC<Omit<ActionButtonItemType, 'key'>> = ({ url, icon, disabled }) => (
		<Link to={disabled ? '#' : (url || '')} style={{ color: 'inherit'}}>
			<IconButton disabled={disabled} color="inherit">
				{icon}
			</IconButton>
		</Link>
	)

	const ActionButton: FC<Omit<ActionButtonItemType, 'key'>> = ({ onClick, icon,disabled }) => (
		<IconButton disabled={disabled} onClick={onClick} color="inherit">
			{icon}
		</IconButton>
	)
	return (
		<AppBar
			elevation={1}
			position="static"
			color="secondary"
		>
			<Toolbar sx={{ flexGrow: 1, paddingTop: 8 }}>
				<Grid container 
					// spacing={0} // TODO: fix
				>
					<Grid sx={{
						height: 36,
						flexGrow: 1,
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}} container>
						<div>
							{backUrl && (
								<Link to={backUrl} style={{ color: 'inherit'}}>
									<IconButton sx={{ color: 'inherit'}}>
										<IconsMap.back />
									</IconButton>
								</Link>
							)}
						</div>
						<div>
							{actionButtons?.map(({ key, component, url, icon, disabled, onClick }) => (
								<Fragment key={key}>
									{component && component}
									{url && <ActionLink disabled={disabled} url={url} icon={icon} />}
									{onClick && <ActionButton disabled={disabled} onClick={onClick} icon={icon} />}
								</Fragment>
							))}
						</div>
					</Grid>
					<Grid item xs={12} sx={{ paddingBottom: 2 }}>
						<Typography variant="h6" noWrap>
							{pageTitle}
							{pageIcon && <Box display="inline" paddingLeft={1}>{pageIcon}</Box>}
						</Typography>
						<Typography variant="subtitle1" noWrap sx={{ height: 30 }}>
							{title}
						</Typography>
					</Grid>
					{children && <Grid item xs={12} sx={{ paddingBottom: 2 }}>
						{children}
					</Grid>}
				</Grid>
			</Toolbar>
		</AppBar>
	)
}
