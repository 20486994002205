import { FC } from 'react'
import { PaperProps as MuiPaperProps } from '@mui/material/Paper'
import CardHeader, { CardHeaderProps } from '@mui/material/CardHeader'
import { StyledPaper } from './Paper.styles'

export interface PaperProps extends MuiPaperProps {
  headerProps?: CardHeaderProps
}

export const Paper: FC<PaperProps> = ({ children, headerProps, ...props}) => (
	<StyledPaper elevation={6} {...props}>
		{headerProps && <CardHeader
			titleTypographyProps={{ variant: 'body1' }}
			{...headerProps}
		/>}
		{children}
	</StyledPaper>
)
