import { FC } from 'react'
import { StyledChip } from './EditorTag.styles'

interface EditorTagProps {
  name?: string
}

export const EditorTag: FC<EditorTagProps> = ({ name }) => {

	if (!name) return <></>

	return <StyledChip color="secondary" label={name} />
}
