import { FC, createContext, ReactNode, useState, SyntheticEvent } from 'react'
import IconButton from '@mui/material/IconButton'
import Snackbar from '@mui/material/Snackbar'
import { IconsMap } from 'components'

export interface SnackbarProps {
	notify: (message: string) => void
}

interface SnackbarStateProps {
	open: boolean
	message: string
}

export const SnackbarContext = createContext<SnackbarProps>({
	notify: () => undefined,
})

export const SnackbarProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const [state, setState] = useState<SnackbarStateProps>({ open: false, message: '' })

	const handleClose = (event: SyntheticEvent | MouseEvent, reason?: string) => {

		setState({
			...state,
			open: false,
		})
	}

	const notify = (message: string) => {
		setState({
			message,
			open: true,
		})
	}

	return (
		<SnackbarContext.Provider value={{ notify }}>
			<Snackbar
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				open={state.open}
				sx={{
					'.MuiSnackbarContent-action': {
						marginRight: 'auto !important',
						marginLeft: '0 !important',
					}
				}}
				autoHideDuration={7000}
				// onClose={() => handleClose()} TODO: fix
				message={state.message}
				action={
					<>
						<IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
							<IconsMap.close fontSize="small" />
						</IconButton>
					</>
				}
			/>
			{children}
		</SnackbarContext.Provider>
	)
}
