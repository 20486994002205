import { FC } from 'react'
import { useClients, useCreditors } from 'hooks/api'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { en, ar } from './translations'
import { DataSelect } from './DataSelect'
import { Client } from 'types/Client'
import { Creditor } from 'types/Creditor'

type selectType = 'client' | 'creditor'

interface DataSelectProps {
  type: selectType
  fieldName: string
  onProceed?: () => void
}

export const NameSelect: FC<DataSelectProps> = ({ type, ...props }) => {
	i18next.addResourceBundle('en', 'DataSelect', en, true, true)
	i18next.addResourceBundle('ar', 'DataSelect', ar, true, true)
	const { t } = useTranslation('DataSelect')

	if (type === 'client') {
		const { getClientsNames, createClient } = useClients()

		const label = t<string>('clientsLabel')
		const placeholder = t<string>('clientsPlaceholder')
		const { data, loading, refetch } = getClientsNames()

		return (
			<DataSelect<Client>
				data={data?.clients}
				label={label}
				placeholder={placeholder}
				loading={loading}
				createCallback={(name, setState) => {
					createClient({ variables: { name }}).then(({ data }) => {
						setState(data?.createClient.id || '')
					}).catch((error) => {
						console.error({ error })
					}).finally(() => {
						refetch().catch((error) => {
							console.error({ error })
						})
					})
				}}
				{...props}
			/>
		)
	}

	if (type === 'creditor') {
		const label = t<string>('creditorsLabel')
		const placeholder = t<string>('creditorsPlaceholder')
		const { getCreditorsNames, createCreditor } = useCreditors()
		const { data, loading, refetch } = getCreditorsNames()

		return (
			<DataSelect<Creditor>
				data={data?.creditors}
				loading={loading}
				label={label}
				placeholder={placeholder}
				createCallback={(name, setState) => {
					createCreditor({ variables: { name }}).then(({ data }) => {
						setState(data?.createCreditor.id || '')
					}).catch((error) => {
						console.error({ error })
					}).finally(() => {
						refetch().catch((error) => {
							console.error({ error })
						})
					})
				}}
				{...props}
			/>
		)
	}

	return null
}
