import { FC, ReactNode } from 'react'
import Container from '@mui/material/Container'

export interface PageProps {
  children: ReactNode
  centered?: boolean
}

export const Page: FC<PageProps> = ({
	children,
	centered,
}) => {
	return (
		<Container
			sx={{
				padding: 0,
				height: '100%',
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				...centered && {
					justifyContent: 'center',
					alignItems: 'center',
				},
			}}
		>
			<>{children}</>
		</Container>
	)
}
