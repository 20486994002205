import {InvoiceType} from '../types'

export const pages = {
	home: '/',
	dashboard: 'dashboard',
	clients: 'clients',
	creditors: 'creditors',
	purchases: 'purchases',
	products: 'products',

}

const dest = {
	ID: ':id',
	SUB_ID: ':subId',
	TAB: ':tab',
	CREATE: 'create',
	NEW: 'new',
	EDIT: 'edit',
	SHOW: 'show',
	STOCKTAKING: 'stocktaking',

	PRODUCTS_STATUS: '/products-status',
	STOCKTACKING_LIST: 'stocktaking-list',
}

const home = `${pages.home}`
const clients = `/${pages.dashboard}/${pages.clients}/`
const creditors = `/${pages.dashboard}/${pages.creditors}/`
const purchases = `/${pages.dashboard}/${pages.purchases}/`
const products = `/${pages.dashboard}/${pages.products}/`

export const routes = {
	home,

	// CLIENTS
	clients,
	clientsNew: `${clients}${dest.NEW}`,
	clientsEdit: `${clients}${dest.EDIT}`,
	clientsShow: `${clients}${dest.SHOW}`,
	clientsStocktaking: `${clients}${dest.STOCKTAKING}`,
	clientsShowTabs: {
		general: 'general',
		balance: 'balance',
		purchase: 'purchase',
		product: 'product',
		stocktakings: 'stocktakings',
	},

	// CREDITORS
	creditors,
	creditorsNew: `${creditors}${dest.NEW}`,
	creditorsEdit: `${creditors}${dest.EDIT}`,
	creditorsShow: `${creditors}${dest.SHOW}`,

	// PURCHASES
	purchases,
	purchaseNew: `${purchases}${dest.NEW}`,
	purchaseEdit: `${purchases}${dest.EDIT}`,
	purchaseShow: `${purchases}${dest.SHOW}`,
	purchasesStocktaking: `${purchases}${dest.STOCKTAKING}`,

	// PRODUCTS
	products,
	productNew: `${products}${dest.NEW}`,
	productEdit: `${products}${dest.EDIT}`,
	productShow: `${products}${dest.SHOW}`,
	productsStocktaking: `${products}${dest.STOCKTAKING}`,
	productsStatus: `${products}${dest.PRODUCTS_STATUS}`,
	productStocktakingList: `${products}${dest.STOCKTACKING_LIST}`,

	...dest,
}


export const generateInvoiceUrl = (token: string, recordId: string, invoiceType: InvoiceType) => {
	const url = process.env.REACT_APP_INVOICES_URL || 'https://invoices.kobo-trade.com'
	let dir = `rev/${recordId}`

	if (invoiceType === 'product') {
		dir = `prd/${recordId}`
	}
	if (invoiceType === 'purchase') {
		dir = `prs/${recordId}`
	}
	return `${url}/${token}/${dir}`
}
