import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { useAuth, useSnackbar } from 'hooks'
import { MuiInput, Form as GeneralForm, ButtonSubmit } from 'components'
import logoImage from 'assets/kobo-logo-small.png'
import { useLogin, LoginRequest } from 'hooks/api/useLogin'

export function Login() {
	const history = useNavigate()
	const { notify } = useSnackbar()
	const { t } = useTranslation()

	const { login } = useAuth()
	const { loginAccount, loading } = useLogin()

	const handleOnSubmit = async (values: LoginRequest) => {
		const {username, password} = values
		await loginAccount({ variables: {username, password} }).then(({data}: any) => {
			login(data?.login.token)
			history('/')
		}).catch((error) => {
			notify(error.networkError ? t<string>('login.networkError') : t<string>('login.credentialsError'))
		})
	}

	return (
		<Grid container component="main" sx={{ height: '100vh' }}>
			<Grid
				item
				xs={false}
				sm={false}
				md={7}
				sx={{
					backgroundColor: (t) =>
						t.palette.mode === 'light' ? t.palette.primary.dark : t.palette.grey[900],

				}}
			/>
			<Grid item xs={12} sm={12} md={5} component={Paper} elevation={6} square>
				<Box
					sx={{
						my: 8,
						mx: 4,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
          
					<img src={logoImage} alt="" height="80" />
					<GeneralForm
						disabledFormWarning
						initialValues={{ username: '', password: '' }}
						validationSchema={Yup.object({
							username: Yup.string().required().label(t('username')),
							password: Yup.string().required().label(t('password'))
						})}

						onSubmit={handleOnSubmit}
					>

						<MuiInput
							label={t<string>('username')}
							name="username"
							margin="normal"
							required
							fullWidth
						/>
						<MuiInput
							label={t<string>('password')}
							name="password"
							type="password"
							margin="normal"
							fullWidth
							required
						/>
						<ButtonSubmit
							fullWidth
							isLoading={loading}
							variant="contained"
							type="login"
						/>
					</GeneralForm>
					<Typography variant="body2" color="text.secondary" align="center"  sx={{ mt: 5 }}>
						{t<string>('copyright')}
						{' © '} {new Date().getFullYear()}
						<br />  
						<Link color="inherit" href="https://kobo-trade.com">
              kobo-trade.com
						</Link>
					</Typography>
				</Box>
			</Grid>
		</Grid>
	)
}
