import { FC } from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import { useButtonWithIconStyles } from './ButtonWithIcon.styles'

interface ButtonWithIconProps extends Omit<ButtonProps, 'label'> {
  label?: string
  icon?: JSX.Element
}
export const ButtonWithIcon: FC<ButtonWithIconProps> = ({ label, onClick, icon, ...props }) => {
	const classes = useButtonWithIconStyles()

	return (
		<div className={classes.wrapper}>
			<Button
				dir="ltr"
				onClick={onClick}
				variant="contained"
				fullWidth
				type="button"
				endIcon={icon}
				{...props}
			>
				{label}
			</Button>
		</div>


	)
}
