import { useContext } from 'react'
import { useLocalStorage } from './useLocalStorage'
import { AccountContext } from 'providers/Account'
import { useNavigate } from 'react-router-dom'
export const useAuth = () => {
	const { loginPreform, logoutPreform } = useContext(AccountContext)
	const { setValue: setAccessToken } = useLocalStorage('access_token')
	const { setValue: setUserType } = useLocalStorage('account_type')
	const history = useNavigate()

	const login = (accessToken: string) => {
		loginPreform(accessToken)
		setAccessToken(accessToken)
	}

	const logout = () => {
		setAccessToken('')
		setUserType('')
		logoutPreform()
		history('/')
	}
	return { login, logout }
}
