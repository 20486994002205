import { FC, ReactNode } from 'react'
import {ErrorBoundary as ReactErrorBoundery} from 'react-error-boundary'

import { Error } from './Error'

export const ErrorBoundery: FC<{ children: ReactNode }> = ({ children }) => {
	return (
		<ReactErrorBoundery FallbackComponent={Error}>
			{children}
		</ReactErrorBoundery>
	)
}
