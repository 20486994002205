import { FC } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

export const ActivityIndicatorView: FC = () => {
	return (
		<Box sx={{
			height: '100%',
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center', 
		}}>
			<CircularProgress color="inherit" size={32} />
		</Box>
	)
}
