import { FC } from 'react'
import { priceSign } from 'globals/signs'
import { useTranslation } from 'react-i18next'
import { devide } from 'globals/math'
import { StyledLabel, StyledValue } from './PriceWithColor.styles'

interface PriceWithColorProps {
  value?: number
  negative?: boolean
  withLabel?: boolean
}

export const PriceWithColor: FC<PriceWithColorProps> = ({ value = 0, negative, withLabel }) => {
	const { t } = useTranslation()
	const translationString = value > 0 ? t<string>('weOwe') : ( value === 0 ? '' : t<string>('theyOwe'))

	return (
		<>
			{withLabel && (
				<StyledLabel component='span'>
					<>{translationString}</>
				</StyledLabel>
			)}
			<StyledValue value={value}  component='span'>
				{priceSign(devide(value, 1))}
			</StyledValue>
		</>
	)
}
