import { FC, ReactNode } from 'react'
import { Formik, Form as FormikForm, FormikConfig } from 'formik'
import { FormWarning } from '../FormWarning'

interface FormProps extends FormikConfig<any> {
  children: ReactNode
  disabledFormWarning?: boolean
}

export const Form: FC<FormProps> = ({ children, disabledFormWarning, ...props }) => {
	return (
		<Formik {...props}>
			<FormikForm>
				<FormWarning disabledFormWarning>
					{children}
				</FormWarning>
			</FormikForm>
		</Formik>
	)
}
