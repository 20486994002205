import {
	AddCircle,
	AddToPhotos,
	ArrowForward,
	ContactMail,
	Close,
	DnsRounded,
	DeleteForever,
	Edit,
	Link,
	ExitToApp,
	Group,
	PrintRounded,
	ArrowDropDown,
	Subject,
	Person,
	AttachMoneyOutlined,
	LocalAtm,
	Home,
	ErrorOutline,
	Menu,
	PermContactCalendar,
	Save,
	Search,
	Widgets,
	MonetizationOn,
	LibraryAddCheck,
	ChevronLeft,
	PostAdd, ListAlt, Image,
} from '@mui/icons-material'

import { CreditorsSvg, CreditorNewSvg } from './custom'

export enum Icons {
  balance = 'balance',
  clients = 'clients',
  client = 'client',
  clientsNew = 'clientsNew',
  creditors = 'creditors',
  creditorsNew = 'creditorsNew',
  balanceNew = 'balanceNew',
  credits = 'credits',
  creditsNew = 'creditsNew',
  image = 'image',
  stocktakings = 'stocktakings',
  productsStocktaking = 'productsStocktaking',
  stocktakingsNew = 'stocktakingsNew',
  products = 'products',
  productsNew = 'productsNew',
  purchases = 'purchases',
  purchasesNew = 'purchasesNew',

  add = 'add',
  back = 'back',
  bill = 'bill',
  close = 'close',
  delete = 'delete',
  details = 'details',
  down = 'down',
  edit = 'edit',
  error = 'error',
  home = 'home',
  login = 'login',
  link = 'link',
  money = 'money',
  menu = 'menu',
  moneySign = 'moneySign',
  next = 'next',
  print = 'print',
  save = 'save',
  search = 'search',
  stocktaking = 'stocktaking',
}

export const IconsMap = {
	[Icons.balance]: LocalAtm,
	[Icons.bill]: LocalAtm,
	[Icons.image]: Image,
	[Icons.clients]: Group,
	[Icons.client]: Person,
	[Icons.clientsNew]: LocalAtm,
	[Icons.creditors]: CreditorsSvg,
	[Icons.creditorsNew]: ContactMail,
	[Icons.balanceNew]: CreditorNewSvg,
	[Icons.credits]: PermContactCalendar,
	[Icons.creditsNew]: ContactMail,
	[Icons.stocktakings]: ListAlt,
	[Icons.stocktakingsNew]: PostAdd,
	[Icons.products]: Widgets,
	[Icons.productsNew]: AddToPhotos,
	[Icons.purchases]: DnsRounded,
	[Icons.purchasesNew]: DnsRounded,

	[Icons.add]: AddCircle,
	[Icons.back]: ArrowForward,
	[Icons.close]: Close,
	[Icons.delete]: DeleteForever,
	[Icons.details]: Subject,
	[Icons.down]: ArrowDropDown,
	[Icons.edit]: Edit,
	[Icons.error]: ErrorOutline,
	[Icons.home]: Home,
	[Icons.link]: Link,
	[Icons.login]: ExitToApp,
	[Icons.menu]: Menu,
	[Icons.moneySign]: AttachMoneyOutlined,
	[Icons.next]: ChevronLeft,
	[Icons.print]: PrintRounded,
	[Icons.money]: MonetizationOn,
	[Icons.save]: Save,
	[Icons.search]: Search,
	[Icons.stocktaking]: LibraryAddCheck,
	[Icons.productsStocktaking]: LibraryAddCheck,
}
