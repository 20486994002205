
// import { makeStyles, createStyles } from '@mui/styles'

// export const useDetailsTableStyles =  makeStyles((theme: any) => createStyles({
//   container: {
//     marginBottom: theme.spacing(1),
//   },
//   labelCell: {
//     verticalAlign: 'baseline',
//   },
// }))


export const useDetailsTableStyles = (params?: any): any => ({}) as any
