import { FC, ReactNode } from 'react'
import Box from '@mui/material/Box'

export const ContentWrapper: FC<{ children: ReactNode }> = ({ children }) => {
	return (
		<Box sx={{ padding: 1 }}>
			{children}
		</Box>
	)
}
