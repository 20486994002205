import { gql } from '@apollo/client'

export const ADD_CREDITOR_BALANCE = gql`
  mutation(
    $creditorId: String!
    $paid: Float!
    $notes: String
  )
  {
    createCreditorBalance(
        creditorId: $creditorId,
        paid: $paid,
        notes: $notes
    ) {
        id
        paid
        notes
        creditor {
          id
          name
          createdBy
          updatedBy
        }
    }
  }
`

export const ADD_CLIENT_BALANCE = gql`
  mutation(
    $clientId: String!
    $paid: Float!
    $notes: String
  )
  {
    createClientBalance(
        clientId: $clientId,
        paid: $paid,
        notes: $notes
    ) {
        id
        paid
        notes
        client {
          id
          name
        }
    }
  }
`
