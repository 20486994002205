import { ReactNode } from 'react'
import { usePage } from 'hooks/usePage'
import { FC } from 'react'

import { ContentHeader, ContentHeaderProps } from './ContentHeader'
import Box from '@mui/material/Box'

interface ContentProps extends Pick<ContentHeaderProps, 'backUrl' | 'actionButtons'> {
  children: ReactNode
  pageIcon?: JSX.Element
  subtitle?: string
  title?: string
  fullHeight?: boolean
  header?: JSX.Element
}

export const Content: FC<ContentProps> = ({
	children,
	title,
	header,
	fullHeight,
	...props
}) => {
	document.title = title || ''
	const { pageTitle, pageIcon } = usePage()
	return (
		<Box sx={[{
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
		}, fullHeight ? { overflow: 'hidden' } : { overflow: 'auto' }]}>
			<ContentHeader
				pageTitle={pageTitle}
				title={title}
				pageIcon={pageIcon || <></>}
				{...props}
			>
				{header}
			</ContentHeader>
			<Box sx={fullHeight ? { flex: 1, overflowY: 'auto' } : { height: '100%' }}>
				{children}
			</Box>
		</Box>
	)
}
