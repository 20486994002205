import { gql } from '@apollo/client'

export const GET_PRODUCTS_LIST=gql`
  query($filterKeyword: String!) {
    products(filterKeyword: $filterKeyword) {
      id
      name
      amount
      unit
    }
  }
`

export const GET_PRODUCTS=gql`
  query($showUnavailable: Boolean, $filterKeyword: String!, $stocktaked: Boolean) {
    products(showUnavailable: $showUnavailable, filterKeyword: $filterKeyword, stocktaked: $stocktaked) {
      id
      name
      amount
      paid
      unit
      unitPrice
      client {
        id
        name
      }
      extraCosts {
        id
        paid
        notes
        order
      }
      available
      purchaseItems {
        id
        amount
        price
        extraCosts
        createdAt
        product {
          id
          unit
          unitPrice
          extraCosts {
            id
            paid
            notes
            order
          }
        }
        purchase {
          id
          client {
            id
            name
          }
        }
      }
    }
  }
`

export const GET_STOCKTAKED_PRODUCTS=gql`
  query($showUnavailable: Boolean, $filterKeyword: String!, $stocktaked: Boolean) {
    products(showUnavailable: $showUnavailable, filterKeyword: $filterKeyword, stocktaked: $stocktaked) {
      id
      name
      amount
      paid
      unit
      unitPrice
      client {
        id
        name
      }
      extraCosts {
        id
        paid
        notes
        order
      }
      available
      purchaseItems {
        id
        amount
        price
        extraCosts
        createdAt
        product {
          id
          unit
          unitPrice
          extraCosts {
            id
            paid
            notes
            order
          }
        }
        purchase {
          id
          client {
            id
            name
          }
        }
      }
    }
  }
`
