import { useMutation } from '@apollo/client'
import { LOGIN_MUTATION } from './useLogin.gql'
import { Account } from 'types/Account'

export interface LoginRequest {
  username: string
  password: string
}

export interface LoginResponse {
  login: {
    token: string
    account: Account
  }
}

export const useLogin = () => {
	const [loginAccount, { loading }] = useMutation<
    LoginResponse,
    LoginRequest
  >(LOGIN_MUTATION)
	return { loginAccount, loading }
}
