import { FC } from 'react'
import { useFormikContext, useField } from 'formik'
import Select, { SelectProps } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { IconsMap } from 'components'

export interface OptionsProp {
  value: any
  label: string
}
export interface MuiSelectProps extends SelectProps {
  name: string
  label: string
  options: OptionsProp[]
  required?: boolean
  selectedValue?: any,
  isLoading?: boolean
  noSemptyOption?: boolean
}

export const MuiSelect: FC<MuiSelectProps> = ({ name, label, options, required, selectedValue, isLoading, noSemptyOption, ...props }) => {
	const  { isSubmitting, handleChange, handleBlur } = useFormikContext()
	const [{ value }, { error }, { setValue }] = useField<string>(name)

	const handleOnBlur: any = (e: any) => {
		setValue(value)
		handleBlur(e)
	}

	const handleOnChange: any = (e: any) => {
		handleChange(e)
	}

	return (
		<>
			{!isLoading && <Select
				inputProps={{
					name: name,
					id: name,
					disabled: isSubmitting,
				}}
				placeholder={label}
				label={label}
				id={name}
				fullWidth
				variant="filled"
				dir="ltr"
				sx={{ textAlign: 'right' }}
				color="primary"
				defaultValue={selectedValue}
				onChange={handleOnChange}
				onBlur={handleOnBlur}
				name={name}
				value={value || undefined}
				required={required}
				startAdornment={error && <IconsMap.error color="error" fontSize="small" />}
				{...props}
			>
				{!noSemptyOption && <option value="" ></option>}
				{options.map((item: OptionsProp) => (
					<MenuItem key={`menu1_${item.value}`} value={item.value || ''}>{item.label}</MenuItem>
				))}
			</Select>}
		</>
	)

}
