import { useQuery } from '@apollo/client'
import { Purchase } from 'types/Purchase'
import { useParams } from 'react-router-dom'
import { GET_PURCHASE } from './usePurchase.gql'

export const usePurchase = () => {
	const { id } = useParams<{ id: string }>()

	return useQuery<{ purchase: Purchase }>(GET_PURCHASE, { variables: { id } })
}
