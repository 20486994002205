export const useLocalStorage = (key: string) => {
	const getValue = async () => {
		return window.localStorage.getItem(key)
	}

	const setValue = (value: string) => {
		return window.localStorage.setItem(key, value)
	}

	return {
		getValue,
		setValue
	}
}
