import { FC } from 'react'
import MuiTableRow, { TableRowProps } from '@mui/material/TableRow'

export const TableRow: FC<TableRowProps> = (props) => {

	return <MuiTableRow sx={{
		'&:nth-of-type(odd)': {
			backgroundColor: 'bg.light',
		},
		padding: 0,
		margin: 0,
	}
	} {...props} />
}
