import { FC, ReactNode } from 'react'
// import { Prompt } from 'react-router-dom' TODO: find a solution
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

interface FormWarning {
  children: ReactNode
  disabledFormWarning?: boolean
}

export const FormWarning: FC<FormWarning> = ({ children, disabledFormWarning }) => {
	const { dirty, isSubmitting } = useFormikContext()
	const { t } = useTranslation()

	return (
		<>
			{/* <Prompt
        when={!disabledFormWarning && dirty && !isSubmitting}
        message={t<string>('formNavigationWarning')}
      /> */}
			{children}
		</>
	)
}
