import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { Dialog } from './Dialog'
import { Form } from '../Form'
import { useSnackbar } from 'hooks/useSnackbar'
import { useMutation } from '@apollo/client'
import { client } from 'apollo'
import { BalanceForm } from './BalanceForm'
import { ADD_CLIENT_BALANCE, ADD_CREDITOR_BALANCE } from './BalanceDialog.gql'
import * as Yup from 'yup'
import { en, ar } from './translations'
interface Props {
  open: boolean
  handleClose: () => void
  name?: string
  clientId?: string
  creditorId?: string
}

export const BalanceDialog: FC<Props> = ({ open, handleClose, name, clientId, creditorId }) => {
	i18next.addResourceBundle('en', 'balanceDialog', en, true, true)
	i18next.addResourceBundle('ar', 'balanceDialog', ar, true, true)

	const { t } = useTranslation('balanceDialog')
	const [createClientBalance] = useMutation(ADD_CLIENT_BALANCE)
	const [createCreditorBalance] = useMutation(ADD_CREDITOR_BALANCE)

	const { notify } = useSnackbar()
	const initialValues = { clientId: '', paid: '' }
	const validationSchema = Yup.object({
		paid: Yup.number()
			.label(t('paid'))
			.required(),
	})

	const successHandler = () => {
		client.resetStore().finally(() => {
			notify(t('successSaving'))
			handleClose()      
		}).catch((e) => {
			console.error(e)
		})
	}


	const errorHandler = (error: any) => {
		notify(t('failedSaving'))
		console.error({ error })
	}

	return (
		<Dialog
			open={open}
			handleClose={handleClose}
			name={name}
			sx={{ marginTop: 2, padding: 0.5 }}
		>
			<Form
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={async({ paid, notes }) => {
					await clientId
						? createClientBalance({ variables: { paid: Number(paid), notes, clientId }})
							.then(successHandler)
							.catch(errorHandler)
						: createCreditorBalance({ variables: { paid: Number(paid), notes, creditorId }})
							.then(successHandler)
							.catch(errorHandler)
				}}
			>
				<BalanceForm />
			</Form>
		</Dialog>
	)
}
