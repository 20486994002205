import { useQuery, useMutation } from '@apollo/client'
import { Client } from 'types/Client'
import { GET_CLIENTS_NAMES, ADD_CLIENT} from './useClients.gql'

interface ClientsApiResponse {
  clients: Client[]
}

interface AddClientMutation{
  createClient: Client
}

export const useClients = () => {
	const [createClient] = useMutation<AddClientMutation, {name: string}>(ADD_CLIENT)


	const getClientsNames = () =>
		useQuery<ClientsApiResponse>(GET_CLIENTS_NAMES, { variables: { filterKeyword: '' }})

	return { getClientsNames, createClient }
}
