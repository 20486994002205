import { FC, ReactNode } from 'react'
import { ApolloProvider as ApolloClientProvider, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

const httpLink = createHttpLink({ uri: process.env.REACT_APP_API_URL })

const authLink = setContext((_, { headers }) => {
	// get the authentication token from local storage if it exists
	const token = localStorage.getItem('access_token')
	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : '',
		}
	}
})

export const client = new ApolloClient({
	link: authLink.concat(httpLink),
	cache: new InMemoryCache(),
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'cache-first'
		}
	}
})

export const ApolloProvider: FC<{ children: ReactNode }> = ({ children }) => (
	<ApolloClientProvider client={client}>
		{children}
	</ApolloClientProvider>
)
