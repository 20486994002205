import { Suspense, StrictMode, lazy } from 'react'
import {createRoot} from 'react-dom/client'
import { Loading } from 'views'
import { ErrorBoundery } from 'components'

import './styles/index.css'
import { ThemeProvider } from './providers/Theme'

const Root = lazy(() => import('./Root'))

const rootElement = document.getElementById('root')!
const root = createRoot(rootElement!)


root.render(
	<StrictMode>
		<ThemeProvider>
			<ErrorBoundery>
				<Suspense fallback={<Loading />}>
					<Root />
				</Suspense>
			</ErrorBoundery>
		</ThemeProvider>
	</StrictMode>,
)
