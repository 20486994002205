import i18n from 'i18next'
import * as dayjs from 'dayjs'

import intervalPlural from 'i18next-intervalplural-postprocessor'
import { initReactI18next } from 'react-i18next'


import commonEn from './locales/common.en.json'
import validationEn from './locales/validation.en.json'
import commonAr from './locales/common.ar.json'
import validationAr from './locales/validation.ar.json'

import purchasesEn from './views/Dashboard/Pages/Purchases/translations/en.json'
import purchasesAr from './views/Dashboard/Pages/Purchases/translations/ar.json'
import productsEn from './views/Dashboard/Pages/Products/translations/en.json'
import productsAr from './views/Dashboard/Pages/Products/translations/ar.json'
import clientsTypesEn from './views/Dashboard/Pages/Clients/translations/en.json'
import clientsTypesAr from './views/Dashboard/Pages/Clients/translations/ar.json'
import creditorsTypesEn from './views/Dashboard/Pages/Creditors/translations/en.json'
import creditorsTypesAr from './views/Dashboard/Pages/Creditors/translations/ar.json'
import homeEn from './views/Dashboard/translations/en.json'
import homeAr from './views/Dashboard/translations/ar.json'

import './locales/yup'

const resources = {
	en: {
		translation: {
			...commonEn,
			...validationEn
		},
		purchases: purchasesEn,
		products: productsEn,
		clients: clientsTypesEn,
		creditors: creditorsTypesEn,
		home: homeEn,
	},
	ar: {
		translation: {
			...commonAr,
			...validationAr
		},
		purchases: purchasesAr,
		products: productsAr,
		clients: clientsTypesAr,
		creditors: creditorsTypesAr,
		home: homeAr,
	},
}

i18n
	.use(intervalPlural)
	.use(initReactI18next)
	.init({
		resources,
		lng: 'ar',

		keySeparator: false,

		interpolation: {
			escapeValue: false
		}
	})
dayjs.locale('ar') // use locale
export default i18n
