import { gql } from '@apollo/client'

export const GET_CLIENTS=gql`
  query($filterKeyword: String!) {
    clients(filterKeyword: $filterKeyword) {
      id
      name
      createdBy
      updatedBy
      purchases {
        id
        paid
        notes
        createdAt
        updatedAt
        createdBy
        purchaseItems {
          id
          amount
          price
          product {
            id
            name
            unit
          }
        }
      }
      balances {
        id
        paid
        createdAt
        createdBy
        notes
      }
      products {
        id
        name
        amount
        unit
        unitPrice
        paid
        extraCosts {
          id
          paid
          notes
          order
        }
      }
    }
  }
`
