import { FC } from 'react'
import MuiTableCell, { TableCellProps } from '@mui/material/TableCell'

export const TableCell: FC<TableCellProps> = (props) => {

	return <MuiTableCell sx={{
		'&:nth-of-type(odd)': {
			backgroundColor: 'action.hover',
		},
		paddingX: 0.5,
		paddingY: 0,
		margin: 0,
		// whiteSpace: 'nowrap',
		maxWidth: 100,
		textAlign: 'right'
  
	}} {...props} />
}
